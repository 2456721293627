$reactify-separator-color-light: #f3f3f3;
$reactify-separator-color: #d7d7d7;
$reactify-background-color: #f8f8f8;
$reactify-foreground-color : white;
$reactify-input-background: white;

$reactify-dark-btn-bg: #575057;
$reactify-light-btn-bg: #d4d4d4;

$reactify-btn-text-color: #fff;

$reactify-theme-color-1: #922c88;
$reactify-theme-color-2: #4556ac;
$reactify-theme-color-3: #af67a4;
$reactify-theme-color-4: #743c6e;
$reactify-theme-color-5: #4b5480;
$reactify-theme-color-6: #795d75;

$primary-color: #303030;
$secondary-color: #8f8f8f;
$muted-color: #909090;

$reactify-gradient-color-1 : #a9449f;
$reactify-gradient-color-2 : #3c164f;
$reactify-gradient-color-3 : #8a3481;

$shadowOffsetsTop : 1 3 10 14 19;
$shadowBlursTop: 1.5 5 10 14 19;
$shadowOpacitiesTop: 0.04 0.1 0.19 0.25 0.3;

$shadowOffsetsBottom : 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.04 0.1 0.2 0.22 0.22;

$getLogo: "/assets/img/logo-dark.png";
$getLogoMobile: "/assets/img/logo-mobile.png";

$lpBackgroundHome: "";
$lpBackgroundSub: "";
$lpBackgroundFooter: "";
$lpBackgroundEllipsisBottom: "";
$lpBackgroundEllipsisTop: "";
//$lpBackgroundHome: "/assets/img/landing-page/home-background-purple.png";
//$lpBackgroundSub: "/assets/img/landing-page/sub-background-purple.png";
//$lpBackgroundFooter: "/assets/img/landing-page/footer-background-purple.png";
//$lpBackgroundEllipsisBottom: "/assets/img/landing-page/white-background-bottom.png";
//$lpBackgroundEllipsisTop: "/assets/img/landing-page/white-background-top.png";

$lpgetLogo: "/assets/img/logo-light.png";
$lpgetLogoPinned: "/assets/img/logo-dark.png";

@import "../mixins.scss";
@import "../reactify.design.scss";