body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.bg-blue-pixel {
  background:
          url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkYPgvCQACIQEaHFnSLAAAAABJRU5ErkJggg==)
          no-repeat
          left top;

  transition-duration: 0.5s;
}

.report-switcher {
  position: fixed;
  z-index: 1000;
  right: 0;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  opacity: 0.3;

  transition-duration: 0.5s;
}

.report-switcher:hover {
  opacity: 1;
}

small .spinner-grow {
  width: 1rem !important;
  height: 1rem !important;
}
.width-inline-select {
  width: 18em;
}

/*.row {*/
/*  display: -webkit-box;*/
/*  display: -webkit-flex;*/
/*  display: -ms-flexbox;*/
/*  display:         flex;*/
/*  flex-wrap: wrap;*/
/*}*/
/*.row > [class*='col-'] {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*}*/


/*.row > .col {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*}*/

.sidebar a.active {
  font-weight: bold;
  color: #4556ac;
}